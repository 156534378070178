import { Auth } from '@aws-amplify/auth'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'

export const ERROR_BAD_REQUEST = {
  code: 400,
  type: 'BAD_REQUEST',
  message: 'bad request'
}
export const ERROR_NOT_FOUND = {
  code: 404,
  type: 'NOT_FOUND',
  message: 'not found'
}

const defaultOptions: any = {
  query: {
    fetchPolicy: 'network-only'
  }
}

/**
 * 認可
 */
const abortController = new AbortController()

const httpLink = createHttpLink({
  uri: process.env.GRAPHQL_ENDPOINT_MAIN,
  // NOTE: apollo clientがクエリ処理後にabortし、sentry session replayがresponse bodyを取得できなかった問題のため, signalプロパティを渡す: https://github.com/getsentry/sentry-javascript/issues/8345#issuecomment-1595585955
  fetchOptions: {
    mode: 'cors',
    signal: abortController.signal
  }
})
const authLink = setContext(async (_, { headers }) => {
  // amplifyからのトークン取得
  const data = await Auth.currentSession()
  const token = data.getAccessToken().getJwtToken()
  return {
    headers: {
      ...headers,
      // トークンをセット
      authorization: `Bearer ${token}`,
      // see: https://gitlab.com/mates-pay/app/new_reco/reco-api/-/issues/2642
      'Mates-App-Name': process.env.APP_NAME === 'RECO_FOR_PARENT' ? 'forParent' : 'forStudent'
    }
  }
})

const link = authLink.concat(httpLink)
const cache = new InMemoryCache()

/**
 * export client
 */
export const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions
})

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3b8dc870 = () => interopDefault(import('../parent/pages/account.vue' /* webpackChunkName: "parent/pages/account" */))
const _96c38a1a = () => interopDefault(import('../parent/pages/account/index.vue' /* webpackChunkName: "parent/pages/account/index" */))
const _27863b82 = () => interopDefault(import('../parent/pages/account/edit/change-password.vue' /* webpackChunkName: "parent/pages/account/edit/change-password" */))
const _54659d89 = () => interopDefault(import('../parent/pages/account/edit/email/index.vue' /* webpackChunkName: "parent/pages/account/edit/email/index" */))
const _68d9da0c = () => interopDefault(import('../parent/pages/account/edit/line.vue' /* webpackChunkName: "parent/pages/account/edit/line" */))
const _0f4f9eba = () => interopDefault(import('../parent/pages/account/edit/email/edit.vue' /* webpackChunkName: "parent/pages/account/edit/email/edit" */))
const _62554317 = () => interopDefault(import('../parent/pages/account/edit/email/new.vue' /* webpackChunkName: "parent/pages/account/edit/email/new" */))
const _3a8e8bea = () => interopDefault(import('../parent/pages/calendar.vue' /* webpackChunkName: "parent/pages/calendar" */))
const _cd136764 = () => interopDefault(import('../parent/pages/calendar/index.vue' /* webpackChunkName: "parent/pages/calendar/index" */))
const _d1b1eb94 = () => interopDefault(import('../parent/pages/calendar/_id.vue' /* webpackChunkName: "parent/pages/calendar/_id" */))
const _19d1f489 = () => interopDefault(import('../parent/pages/grades.vue' /* webpackChunkName: "parent/pages/grades" */))
const _e6e59f68 = () => interopDefault(import('../parent/pages/grades/index.vue' /* webpackChunkName: "parent/pages/grades/index" */))
const _189b3ec0 = () => interopDefault(import('../parent/pages/grades/original-exam/_id.vue' /* webpackChunkName: "parent/pages/grades/original-exam/_id" */))
const _150e8306 = () => interopDefault(import('../parent/pages/grades/school-exam/_id.vue' /* webpackChunkName: "parent/pages/grades/school-exam/_id" */))
const _f6942312 = () => interopDefault(import('../parent/pages/koma-absence-request.vue' /* webpackChunkName: "parent/pages/koma-absence-request" */))
const _7e3c9edb = () => interopDefault(import('../parent/pages/koma-add-request.vue' /* webpackChunkName: "parent/pages/koma-add-request" */))
const _33dd0a65 = () => interopDefault(import('../parent/pages/koma-transfer-request.vue' /* webpackChunkName: "parent/pages/koma-transfer-request" */))
const _03117e54 = () => interopDefault(import('../parent/pages/maintenance.vue' /* webpackChunkName: "parent/pages/maintenance" */))
const _5bd8211c = () => interopDefault(import('../parent/pages/materials/index.vue' /* webpackChunkName: "parent/pages/materials/index" */))
const _6bfb716b = () => interopDefault(import('../parent/pages/notifications.vue' /* webpackChunkName: "parent/pages/notifications" */))
const _13e9fbae = () => interopDefault(import('../parent/pages/notifications/index.vue' /* webpackChunkName: "parent/pages/notifications/index" */))
const _7101dcd4 = () => interopDefault(import('../parent/pages/notifications/_id.vue' /* webpackChunkName: "parent/pages/notifications/_id" */))
const _6cee1d78 = () => interopDefault(import('../parent/pages/sign-in.vue' /* webpackChunkName: "parent/pages/sign-in" */))
const _717a04de = () => interopDefault(import('../parent/pages/timeline/index.vue' /* webpackChunkName: "parent/pages/timeline/index" */))
const _549a45dc = () => interopDefault(import('../parent/pages/line-link/sign-in.vue' /* webpackChunkName: "parent/pages/line-link/sign-in" */))
const _0a261b55 = () => interopDefault(import('../parent/pages/index.vue' /* webpackChunkName: "parent/pages/index" */))
const _4c5b265a = () => interopDefault(import('../parent/pages/materials/_id.vue' /* webpackChunkName: "parent/pages/materials/_id" */))
const _5eeea151 = () => interopDefault(import('../parent/pages/timeline/_komaId.vue' /* webpackChunkName: "parent/pages/timeline/_komaId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _3b8dc870,
    children: [{
      path: "",
      component: _96c38a1a,
      name: "account"
    }, {
      path: "edit/change-password",
      component: _27863b82,
      name: "account-edit-change-password"
    }, {
      path: "edit/email",
      component: _54659d89,
      name: "account-edit-email"
    }, {
      path: "edit/line",
      component: _68d9da0c,
      name: "account-edit-line"
    }, {
      path: "edit/email/edit",
      component: _0f4f9eba,
      name: "account-edit-email-edit"
    }, {
      path: "edit/email/new",
      component: _62554317,
      name: "account-edit-email-new"
    }]
  }, {
    path: "/calendar",
    component: _3a8e8bea,
    children: [{
      path: "",
      component: _cd136764,
      name: "calendar"
    }, {
      path: ":id",
      component: _d1b1eb94,
      name: "calendar-id"
    }]
  }, {
    path: "/grades",
    component: _19d1f489,
    children: [{
      path: "",
      component: _e6e59f68,
      name: "grades"
    }, {
      path: "original-exam/:id?",
      component: _189b3ec0,
      name: "grades-original-exam-id"
    }, {
      path: "school-exam/:id?",
      component: _150e8306,
      name: "grades-school-exam-id"
    }]
  }, {
    path: "/koma-absence-request",
    component: _f6942312,
    name: "koma-absence-request"
  }, {
    path: "/koma-add-request",
    component: _7e3c9edb,
    name: "koma-add-request"
  }, {
    path: "/koma-transfer-request",
    component: _33dd0a65,
    name: "koma-transfer-request"
  }, {
    path: "/maintenance",
    component: _03117e54,
    name: "maintenance"
  }, {
    path: "/materials",
    component: _5bd8211c,
    name: "materials"
  }, {
    path: "/notifications",
    component: _6bfb716b,
    children: [{
      path: "",
      component: _13e9fbae,
      name: "notifications"
    }, {
      path: ":id",
      component: _7101dcd4,
      name: "notifications-id"
    }]
  }, {
    path: "/sign-in",
    component: _6cee1d78,
    name: "sign-in"
  }, {
    path: "/timeline",
    component: _717a04de,
    name: "timeline"
  }, {
    path: "/line-link/sign-in",
    component: _549a45dc,
    name: "line-link-sign-in"
  }, {
    path: "/",
    component: _0a261b55,
    name: "index"
  }, {
    path: "/materials/:id",
    component: _4c5b265a,
    name: "materials-id"
  }, {
    path: "/timeline/:komaId",
    component: _5eeea151,
    name: "timeline-komaId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
